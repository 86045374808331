<template>
  <transition
    enter-active-class="ease-out duration-300"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-200"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      class="inset-0 z-30
      flex items-center justify-center"
      v-show="isLoading"
    >
      <DgIcon
        size="60"
        name="spinner"
        color="ash-600"
        class="animate-spin-reverse"
      />
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Loading',
  components: {
    DgIcon,
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state?.isLoading,
    }),
  },
  methods: {
    ...mapMutations({
      mutationsSetIsLoading: 'setIsLoading',
    }),
  },
};
</script>

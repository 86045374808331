<template>
  <div class="flex flex-col items-center justify-center lg:py-0 py-10">
    <DgIcon size="40"
      name="exclamation-circle"
      color="red-600"
      class="mb-6" />
    <p class="lg:text-3xl text-2xl text-ash-800 mb-5">下載失敗</p>
    <p class="lg:text-sm text-xs text-ash-600 text-center leading-loose mb-6"
      :style="{
            'max-width': '360px'
          }">{{msg}}</p>
    <router-link to="/"
      tag="button"
      class="border border-primary rounded-full">
      <p class="lg:text-base text-sm text-primary px-7 py-2">返回資料下載</p>
    </router-link>
  </div>
</template>

<script>
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Error',
  components: {
    DgIcon,
  },
  props: ['msg'],
};
</script>

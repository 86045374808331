<template>
  <div class="flex flex-col items-center justify-center lg:py-0 py-10">
    <DgIcon size="40"
      name="check-circle"
      color="primary-gradient-to-b"
      class="mb-6" />
    <p class="lg:text-3xl text-2xl text-ash-800 mb-5">下載序列進行中</p>
    <p class="lg:text-sm text-xs text-ash-600 text-center leading-loose mb-6"
      :style="{
            'max-width': '360px'
          }">請勿關閉視窗，視檔案大小需約等待10-50秒。 <br> 儲存後即完成下載，感謝你您耐心等候。</p>
    <router-link to="/"
      tag="button"
      class="border border-primary rounded-full">
      <p class="lg:text-base text-sm text-primary px-7 py-2">返回資料下載</p>
    </router-link>
  </div>
</template>

<script>
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Success',
  components: {
    DgIcon,
  },
};
</script>

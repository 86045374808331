<template>
  <div class="bg-ash-100 bg-opacity-50 flex flex-col justify-center items-center p-5">
    <div
      class="grid lg:grid-cols-2 grid-cols-1 bg-white shadow w-full h-full max-w-1200 max-auto p-5"
      :style="isMobile ? null : {
        'max-height': '450px'
      }">
      <!-- left -->
      <!-- <Success /> -->
      <!-- <Loading /> -->
      <component :is="componentName"
        :msg="message" />
      <!-- right -->
      <Follow />
    </div>

    <div class="flex lg:flex-row flex-col items-center my-10">
      <p class="lg:text-base text-sm text-ash-600">如有任何其他緊急問題，歡迎撥打免付費電話</p>
      <a :href="`tel:${phoneNumber}`"
        class="flex items-center">
        <DgIcon size="12"
          name="phone"
          color="ash-600"
          class="mx-1.5" />
        <p class="lg:text-base text-sm text-ash-600">{{phoneNumber}}</p>
      </a>
    </div>
  </div>
</template>

<script>
import DgIcon from '@/components/base/DgIcon.vue';
import Follow from '@/components/Follow.vue';
import Loading from '@/components/Loading.vue';
import { mapMutations, mapState } from 'vuex';
import requestApi from '@/lib/http/index';
import Success from './Success.vue';
import Error from './Error.vue';

export default {
  name: 'Downloads',
  inject: ['device'],
  components: {
    DgIcon,
    Follow,
    Success,
    Loading,
    Error,
  },
  data() {
    return {
      phoneNumber: '0800-888-162',
      componentName: 'Loading',
      message: '建立下載連結錯誤，請重新申請。',
    };
  },
  computed: {
    ...mapState(['init', 'isLogin']),
    isMobile() {
      return this.device.isMobile;
    },
  },
  watch: {
    init: {
      handler(val) {
        if (val) {
          const { notify = '' } = this.$route.query;
          if (this.isLogin || notify === '1') {
            this.methodGetLink();
          }

          if (!this.isLogin && notify === '') {
            this.componentName = 'Error';
            this.message = '尚未登入無法下載您申請的資料';
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.mutationsLoading(true);
    // if (this.isLogged) {
    //   this.mutationsLoading(true);
    //   this.methodGetLink();
    // } else {

    // }
  },
  // mounted() {
  //   const { notify = '' } = this.$route.query;
  //   console.log('👻 👻  ~ file: Index.vue ~ line 88 ~ updated ~ notify', notify);
  //   this.mutationsLoading(true);

  //   if (this.isLogin || notify === '1') {
  //     this.methodGetLink();
  //   }

  //   if (!this.isLogin && notify === '') {
  //     this.componentName = 'Error';
  //     this.message = '尚未登入無法下載您申請的資料';
  //   }
  // },
  methods: {
    ...mapMutations({
      mutationsLoading: 'setIsLoading',
    }),
    async methodGetLink() {
      this.mutationsLoading(true);
      const { uuid = null, notify = '' } = this.$route.query;
      if (!uuid) {
        this.componentName = 'Error';
      } else {
        const api = `downloads${notify === '1' ? '.notify' : ''}`;
        const { status, result, message } = await requestApi(api, { uuid });
        const { url = null } = result?.data ?? {};
        if (status && url !== null) {
          this.componentName = 'Success';
          // window.location.href = url;
          this.fileDownload(result.data);
        } else {
          this.componentName = 'Error';
          this.message = !status ? message : this.message;
        }
      }
      setTimeout(() => {
        this.mutationsLoading(false);
      }, 300);
    },
    async fileDownload({ uuid, name }) {
      const file = await requestApi('downloads.file', { uuid });
      // console.log('👻 👻  ~ file: Index.vue ~ line 104 ~ fileDownload ~ file', file);
      const targetUrl = window.URL.createObjectURL(file);
      const link = window.document.createElement('a');
      link.href = targetUrl;
      link.setAttribute('download', name);
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
    },
  },
};
</script>

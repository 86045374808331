<template>
  <div
    class="lg:border-l lg:border-t-0 border-t border-ash-600 border-opacity-20 flex flex-col
        items-center justify-center lg:py-0 py-6"
  >
    <div class="lg:mb-5 mb-2">
      <p class="lg:text-base text-sm text-ash-800 mb-5">關注鼎新</p>
    </div>
    <div
      class="grid grid-cols-4 gap-7"
      :style="{
        'max-width': '340px',
      }"
    >
      <a
        v-for="(item, idx) in list"
        :key="`follow-item-${item.label}-${idx}`"
        target="_blank"
        :href="item.href"
        class="flex flex-col items-center"
      >
        <DgIcon size="40" :name="item.icon" class="mb-3.5" />
        <p class="lg:text-base text-sm text-ash-600 whitespace-pre text-center">{{ item.label }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Follow',
  components: { DgIcon },
  data() {
    return {
      list: [
        {
          icon: 'facebook',
          label: 'Facebook',
          href: 'https://bit.ly/32LKmCz',
        },
        {
          icon: 'line',
          label: 'LINE',
          href: 'https://s.aiii.ai/g/980ace',
        },
        {
          icon: 'youtube',
          label: 'YouTube',
          href: 'https://bit.ly/31dbl9E',
        },
        {
          icon: 'logo',
          label: '就享知\n知識平台',
          href: 'https://bit.ly/3lmtEjr',
        },
      ],
      phoneNumber: '0800-888-162',
    };
  },
};
</script>
